import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { Puff } from "react-loader-spinner";
import { CreateStockPlus, EditStockPlus, getStockPlus } from "UpdatedFeatures/api";
// import AddStockModal from "./AddStockModal";
// import EditStockModal from "./EditStockModal";
import { toast } from "react-toastify";
import ImportExport from "components/common/ImportExport";

export default function StockManagementTable() {
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [stock, setStock] = useState(null);
    const queryClient = useQueryClient();

    const { data: StockPlus, error, isLoading, isError } = useQuery({
        queryKey: ["StockPlus", page, perPage, searchTerm],
        queryFn: () => getStockPlus(page, perPage, searchTerm),
        keepPreviousData: true,
    });

    const createMutation = useMutation({
        mutationFn: (data) => CreateStockPlus(data),
        onSuccess: () => {
            queryClient.invalidateQueries(["StockPlus"]);
            setOpenAddModal(false);
            toast.success("Le produit a été ajouté au stock !");
        },
        onError: (error) => {
            toast.error(`Erreur lors de l'ajout : ${error.message}`);
            setOpenAddModal(false);
        },
    });

    const editMutation = useMutation({
        mutationFn: (data) => EditStockPlus(data),
        onSuccess: () => {
            queryClient.invalidateQueries(["StockPlus"]);
            setOpenEditModal(false);
            toast.success("Les informations du stock ont été modifiées !");
        },
        onError: (error) => {
            toast.error(`Erreur lors de la modification : ${error.message}`);
            setOpenEditModal(false);
        },
    });

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchTerm(search);
    };

    const handleEdit = (stock) => {
        setStock(stock);
        setOpenEditModal(true);
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-12">
            {/* <AddStockModal open={openAddModal} setOpen={setOpenAddModal} mutation={createMutation} /> */}
            {/* <EditStockModal open={openEditModal} setOpen={setOpenEditModal} mutation={editMutation} data={stock} /> */}
            <div className="sm:flex sm:items-center justify-between">
                <div className="relative w-1/3">
                    <form onSubmit={handleSearchSubmit} className="flex items-center">
                        <input
                            type="text"
                            placeholder="Rechercher..."
                            value={search}
                            onChange={handleSearchChange}
                            className="block w-full p-3 pl-10 text-sm border border-[#b269ae] rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-300"
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <IoSearch color="purple" />
                        </button>
                    </form>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 flex items-center gap-3">
                    <ImportExport />
                    <button
                        onClick={() => setOpenAddModal(true)}
                        type="button"
                        className="flex items-center justify-center px-3 py-1 rounded-full bg-[#b269ae] text-white font-semibold transition-all duration-300 hover:bg-yellow-400"
                    >
                        <span className="text-sm">+ Ajouter Produit</span>
                    </button>
                </div>
            </div>

            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-[#b269ae]">
                                    <tr>
                                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6">
                                            Nom du Produit
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Quantité
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Catégorie
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Fournisseur
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Emplacement
                                        </th>
                                        <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Actions</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {isLoading ? (
                                        <tr>
                                            <td colSpan="6" className="text-center py-4">
                                                <Puff visible={true} height="90" width="50" color="#895a7c" ariaLabel="puff-loading" />
                                            </td>
                                        </tr>
                                    ) : isError ? (
                                        <tr>
                                            <td colSpan="6" className="text-center py-4">
                                                Error: {error.message}
                                            </td>
                                        </tr>
                                    ) : (
                                        StockPlus?.data.map((item) => (
                                            <tr key={item.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {item.name}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {item.quantity}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {item.category}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {item.supplier}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {item.location}
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <button
                                                        className="text-[#b269ae] hover:text-purple-300"
                                                        onClick={() => handleEdit(item)}
                                                    >
                                                        <FaPen />
                                                    </button>
                                                    <button className="text-[#b269ae] hover:text-purple-300 ml-4">
                                                        <FaTrash />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
